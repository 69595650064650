import Vue from "vue";
import router from "../router/";
import {
  createNewUser,
  createUser,
  getUser,
  loginUser,
  recoveryPassword,
  updateUser,
} from "../services/User";
const userDefault = {
  id: null,
  username: "",
  email: "",
  role: "",
  plan: {},
  token: "",
};

const defaultClient = {
  name: "",
  email: "",
  phone: "",
  mobilePhone: "",
  cpfCnpj: "",
  postalCode: "",
  address: "",
  addressNumber: "",
  complement: "",
  province: "",
  externalReference: "",
};

const creditCard = {
  holderName: "",
  number: "",
  expiryMonth: "",
  expiryYear: "",
  ccv: "",
};

const creditCardHolder = {
  name: "",
  email: "",
  cpfCnpj: "",
  postalCode: "",
  addressNumber: "",
  phone: "",
  mobilePhone: "",
};

export default {
  namespaced: true,
  state: {
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : userDefault,
    users: [],
    selectedUser: null,
    client: defaultClient,
    creditCard: creditCard,
    creditCardHolder: creditCardHolder,
    loadingUsers: false,
    listPlans: [],
    formErrors: [],
  },
  mutations: {
    SET_USER(state, value) {
      state.user = value;
    },
    SET_USER_PAYMENT(state, value) {
      state.client = { ...value };
      if (value.first_name != undefined) state.client.name = value.first_name;
      if (value.id != undefined) state.client.externalReference = value.id;
    },
    SET_CREDIT_CARD(state, value) {
      state.creditCard = value;
    },
    SET_CREDIT_CARD_HOLDER(state, value) {
      state.creditCardHolder = value;
    },
    SET_LOADING_USERS(state, value) {
      state.loadingUsers = value;
    },
    SET_ERRORS(state, value) {
      state.formErrors = value;
    },
    SET_USERS(state, value) {
      state.users = value;
    },
    SET_SELECTED_USER(state, value) {
      state.selectedUser = value;
    },
    SET_PLANS(state, value) {
      state.listPlans = value;
    },
  },
  actions: {
    async LOGOUT({ commit }) {
      localStorage.removeItem("user");
      commit("SET_USER", "");
      window.location.replace("/admin/login");
    },
    async LOGOUTV2({ commit }) {
      localStorage.removeItem("user");
      commit("SET_USER", "");
      window.location.replace("/login");
    },
    SET_USER_PAYMENT({ commit }, newValue) {
      commit("SET_USER_PAYMENT", newValue);
    },
    SET_CREDIT_CARD({ commit }, newValue) {
      commit("SET_CREDIT_CARD", newValue);
    },
    SET_CREDIT_CARD_HOLDER({ commit }, newValue) {
      commit("SET_CREDIT_CARD_HOLDER", newValue);
    },
    CLEAR_USER({ commit }) {
      commit("SET_USER_PAYMENT", userDefault);
    },
    async SET_TOKEN(_state, payload) {
      if (!payload) {
        localStorage.removeItem("token");
        Vue.prototype.$api.defaults.headers.common["Authorization"] = "";
        return true;
      }
      localStorage.setItem("token", payload);
      Vue.prototype.$api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${payload}`;
      return true;
    },
    async REQUEST_USERS({ commit }) {
      const response = await getUser();
      commit("SET_USERS", response.data);
      return response;
    },
    async UPDATE_USER({ commit }, payload) {
      const response = await updateUser(payload.form, payload.idUser);
      console.log(commit);
      return response;
    },
    async CREATE_USER({ commit }, payload) {
      console.log(payload);
      const response = await createUser(payload);
      console.log(commit);
      return response;
    },
    async LOGIN_USER({ commit, dispatch }, payload) {
      const response = await loginUser(payload);
      commit("SET_USER", response.data);
      console.log("response.data", response.data);
      // user - Localstorage
      localStorage.setItem("user", JSON.stringify(response.data));
      dispatch("SET_TOKEN", response.data.token);
      if (response.data.type == "admin") router.push({ path: "/painel" });
      return response;
    },
    async RECOVERY_PASSWORD({ commit, dispatch }, payload) {
      const response = await recoveryPassword(payload);
      console.log(commit, dispatch);
      return response;
    },
    async LOGIN_USER_PAYMENT({ commit }, payload) {
      const response = await loginUser(payload);
      commit("SET_USER_PAYMENT", response.data);
      return response;
    },
    async LOGOUT_USER({ commit, dispatch }) {
      localStorage.removeItem("user");
      commit("SET_USER", "");
      commit("Wallet/SET_DEPENDENTS", [], { root: true });
      dispatch("SET_TOKEN", false);
      return true;
    },
    async SIGN_UP_POST({ commit, dispatch }, payload) {
      try {
        const response = await createNewUser(payload);
        commit("SET_USER", response.data);
        localStorage.setItem("user", JSON.stringify(response.data));
        dispatch("SET_TOKEN", response.data.token);
        router.push({ path: "/classItems" });
        return response;
      } catch (err) {
        commit("SET_ERRORS", err.response.data);
        return err.response.data;
      }
    },
  },
  getters: {
    async CHECK_TOKEN() {
      return localStorage.getItem("token");
    },
    IS_LOGGED({ user }) {
      const userInfo = { ...user };
      delete userInfo.plan;
      return !!Object.values(userInfo).filter((item) => item).length;
    },
    GET_USER_PAYMENT({ client }) {
      return client;
    },
    GET_CREDIT_CARD({ creditCard }) {
      return creditCard;
    },
    GET_CREDIT_CARD_HOLDER({ creditCardHolder }) {
      return creditCardHolder;
    },
    GET_USER({ user }) {
      return user;
    },
    GET_FORM_ERRORS({ formErrors }) {
      if (formErrors === []) return formErrors;
      return Object.keys(formErrors).map(
        (key) => `${key} ${Object.values(formErrors)}`
      );
    },
    GET_USERS({ users }) {
      return users;
    },
    GET_LOADING_USERS({ loadingUsers }) {
      return loadingUsers;
    },
    GET_SELECTED_USER({ selectedUser }) {
      return selectedUser;
    },
    GET_PLANS_LIST({ listPlans }) {
      return listPlans;
    },
  },
};
