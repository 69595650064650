<template>
  <v-container style="margin-top: 10px;">
    <v-btn color="success" @click="$router.push('/createUser')" style="margin-bottom: 10px">
      Criar Usuário
      <v-icon>
        mdi-plus
      </v-icon>
    </v-btn>
    <br />
    <v-form>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="dataIni"
            label="Data Inicial"
            type="date"            
            prepend-icon="mdi-calendar"
          ></v-text-field>
          <!-- <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormattedIni"
                label="Data Inicial"
                persistent-hint
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dataIni"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu> -->
        </v-col>
        
        <v-col cols="12" md="4">
          <v-text-field
            v-model="dataFin"
            label="Data Final"
            type="date"            
            prepend-icon="mdi-calendar"
          ></v-text-field>
          <!-- <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormattedFin"
                label="Data Final"
                persistent-hint
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dataFin"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu> -->
        </v-col>
        <v-col cols="12" md="4">
            <v-select
              v-model="paymentStatusFilter"
              :items="paymentStatusType"
              item-text="title"
              item-value="id"
              label="Selecione um status"
              outlined
              dense
            ></v-select>
        </v-col>
      </v-row>
    </v-form>
    <v-row align="end" justify="end">
      <v-btn color="warning" style="margin: 5px" depressed @click="downloadReport()" :disabled="disabledButton">
        Exportar
      </v-btn>
      <v-btn color="primary" style="margin: 5px" depressed @click="getUsersFilter()" :disabled="disabledButton">
        Filtrar
      </v-btn>
      <v-btn color="error" style="margin: 5px" depressed @click="removeFilters()" :disabled="disabledButton">
        Remover filtros
      </v-btn>
    </v-row>
     <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        style="margin-top: 10px; margin-bottom: 10px"
      ></v-text-field>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="usersList"
      :items-per-page="10"
      :search="search"
      class="elevation-1">
     <template v-slot:item.payment_status="{ item }">
      <div v-if="approvedStatus.includes(item.payment_status)">
        <v-icon color="success">
          mdi-check
        </v-icon>
      </div>
      <div v-else>
        <v-icon aria-checked="true" color="error">
          mdi-close
        </v-icon>
      </div>
    </template>
    <template v-slot:item.image="{ item }">
      <div style="display: flex; height: auto;">
        <img style="object-fit: cover;" width="85" height="80" :src="item.avatar" />
        <v-icon small class="mr-2" style="color: red;" @click="removePhoto(item.id)">
          mdi-delete
        </v-icon>
      </div>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="$router.push(`/editUser/${item.id}`)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="alert(item.id + 'asda')"
      >
        mdi-delete
      </v-icon>
    </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex';
import moment from 'moment';
moment.locale('pt-br')

export default {
  methods: {
    ...mapActions("User", ["REQUEST_USERS"]),
    formatDate (date) {
        if (!date) return null
          date = moment(date).format('L');
        return date
    },
    async removePhoto(id) {
      this.loading = true;
      this.disabledButton = true;
      const url = `/users/${id}/delete_avatar`;
      this.$api.delete(url).then(() => {
        this.loading = false;
        this.disabledButton = false;
        this.REQUEST_USERS();
      }).catch(error => {
        this.loading = false;
        this.disabledButton = false;
        console.error('Error removing photo:', error);
      });
    },
    async downloadReport() {
      this.loading = true;
      this.disabledButton = true;
      const url = `/export_users_payments?status=${this.paymentStatusFilter}&initial_date=${this.dataIni}&final_date=${this.dataFin}`;
      this.$api.get(url).then((response) => {
        this.loading = false;
        this.disabledButton = false;

        let mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const url = mediaType + response.data;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'relatorio_usuarios_pagamentos.xlsx'); // Set the filename for the downloaded file
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        this.loading = false;
        this.disabledButton = true;
        console.error('Error downloading Excel file:', error);
      });
    },
    async getUsersFilter() {
      this.loading = true;
      this.disabledButton = true;
      const url = `/search_user?paymentStatus=${this.paymentStatusFilter}&dataIni=${this.dataIni}&dataFin=${this.dataFin}`
      const resp = await this.$api.get(url);
      // console.log('Resp users filter: ', resp.data.length);
      resp.data.map((u) => {
        u.expiration_date = this.formatDate(u.expiration_date);
      })
      
      this.usersList = resp.data;
      this.loading = false;
      this.disabledButton = false;
      return true;
    },
    async removeFilters() { 
      this.loading = true;
      this.disabledButton = true;
      const url = `/search_user`
      this.dataIni = '';
      this.dataFin = '';
      this.paymentStatusFilter = '';
      const resp = await this.$api.get(url);
      this.usersList = resp.data;      
      this.loading = false;
      this.disabledButton = false;
      return true;
    }
  },
  watch: {
     menu1 (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
  },
  computed: {
    computedDateFormattedIni () {
      return this.formatDate(this.dataIni)
    },
    computedDateFormattedFin () {
      return this.formatDate(this.dataFin)
    },
  },
  data() {
    return {
      search: '',
      activePicker: null,
      dataIni: moment().startOf('month').format('YYYY-MM-DD'),
      dataFin: moment().format('YYYY-MM-DD'),
      paymentStatusFilter: '',
      menu1: false,
      menu2: false,
      paymentStatusType: [
        {id: "", title: "Todos"},     
        {id: "approved", title: "Pago"},
        {id: "not_payed", title: "Não Pago"}
      ],
      usersList: [],
      loading: false,
      disabledButton: false,
      approvedStatus: ['approved', 'PAYMENT_CONFIRMED'],
      headers: [
        { text: "Id", value: "id" },
        { text: "Email", value: "email" },
        { text: "Nome", value: "first_name" },
        { text: "Status de pagamento", value: "payment_status" },
        { text: "Data de vencimento", value: "expiration_date" },
        { text: "Data de criação", value: "created_at" },
        { text: "Foto", value: "image" },
        { text: "Ações", value: "actions" },

      ],
    };
  },
  async mounted() {
    // this.loading = true;
    await this.getUsersFilter();
    // this.loading = false;
    // console.log('Users: ', this.usersList);
  },
};
</script>
